import Facebook from './assets/svg/Facebook';
import GitHub from './assets/svg/Github';
import Instagram from './assets/svg/Instagram';
import LinkedIn from './assets/svg/Linkedin';
import Avatar from './assets/img/avatar.jpeg';
// import { Lazy } from 'react-lazy';

function App() {
  return (
    <div className="flex h-screen w-screen justify-center items-center">
      <div className="md:flex bg-gray-50 rounded-xl p-8 shadow">
        <img 
          className="w-32 h-32 rounded-full shadow-lg h-auto mx-auto"
          src={ Avatar }
          alt="..." />
        <div className="flex w-full px-3">
          <div className="flex flex-col md:space-x-16 w-full text-center md:text-right text-gray-700 font-semibold relative pt-3 md:pt-0 antialiased">
            <div className="text-2xl text-black leading-tight font-sans">Andy Fudiko</div>
            <div className="text-normal italic text-gray-500"><span className="border-b border-dashed border-gray-500 pb-1">Software Engineer</span></div>
            <div className="md:absolute bottom-0 right-0 pt-5 md:pt-0">
              <div className="flex flex-row">
                <a href="https://github.com/afudiko" target="_blank" rel="noreferrer">
                  <GitHub />
                </a>
                <a href="https://facebook.com/afudiko" target="_blank" rel="noreferrer">
                  <Facebook />
                </a>
                <a href="https://linkedin.com/in/afudiko" target="_blank" rel="noreferrer">
                  <LinkedIn />
                </a>
                <a href="https://instagram.com/andy.fudiko" target="_blank" rel="noreferrer">
                  <Instagram />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
