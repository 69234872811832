import React from 'react';

const LinkedIn = () => {
  return (
    <div className="LinkedIn cursor-pointer">
      <svg 
        className="transition fill-current text-linkedin bg-white hover:text-white hover:bg-linkedin rounded-full w-8 h-8 icon__icon" 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="-2 -2 24 24" 
        width="24" height="24" 
        preserveAspectRatio="xMinYMin">
          <path d="M15 11.13v3.697h-2.143v-3.45c0-.866-.31-1.457-1.086-1.457-.592 0-.945.398-1.1.784-.056.138-.071.33-.071.522v3.601H8.456s.029-5.842 0-6.447H10.6v.913l-.014.021h.014v-.02c.285-.44.793-1.066 1.932-1.066 1.41 0 2.468.922 2.468 2.902zM6.213 5.271C5.48 5.271 5 5.753 5 6.385c0 .62.466 1.115 1.185 1.115h.014c.748 0 1.213-.496 1.213-1.115-.014-.632-.465-1.114-1.199-1.114zm-1.086 9.556h2.144V8.38H5.127v6.447z" />
          <path d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0 2C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z" />
      </svg>
    </div>
  );
}

export default LinkedIn;
