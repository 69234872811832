import React, {useState} from 'react';

const Instagram = () => {
  const [hover, setHover] = useState(false)

  return (
    <div 
      className="Instagram cursor-pointer"
      onMouseEnter={() => { setHover(true) }}
      onMouseLeave={() => { setHover(false);}}>
        <svg 
          style={{ ...(hover ? {fill: "white", background: "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)"}  : null) }}
          className="transition rounded-lg w-8 h-8 icon__icon" 
          xmlns="http://www.w3.org/2000/svg" 
          viewBox="-2 -2 24 24" 
          width="24" height="24" preserveAspectRatio="xMinYMin" 
          fill="url(#gradient)">
          
            <defs>
              <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" style={{ stopColor:"#b794f4", stopOpacity:"1" }} />
                <stop offset="50%" style={{ stopColor:"#ed64a6", stopOpacity:"1" }} />
                <stop offset="100%" style={{ stopColor:"#f56565", stopOpacity:"1" }} />
              </linearGradient>
            </defs>

            <path d="M14.017 0h-8.07A5.954 5.954 0 0 0 0 5.948v8.07a5.954 5.954 0 0 0 5.948 5.947h8.07a5.954 5.954 0 0 0 5.947-5.948v-8.07A5.954 5.954 0 0 0 14.017 0zm3.94 14.017a3.94 3.94 0 0 1-3.94 3.94h-8.07a3.94 3.94 0 0 1-3.939-3.94v-8.07a3.94 3.94 0 0 1 3.94-3.939h8.07a3.94 3.94 0 0 1 3.939 3.94v8.07z" />
            <path d="M9.982 4.819A5.17 5.17 0 0 0 4.82 9.982a5.17 5.17 0 0 0 5.163 5.164 5.17 5.17 0 0 0 5.164-5.164A5.17 5.17 0 0 0 9.982 4.82zm0 8.319a3.155 3.155 0 1 1 0-6.31 3.155 3.155 0 0 1 0 6.31z" />
            <circle cx="15.156" cy="4.858" r="1.237" />
        </svg>
    </div>
  );
}

export default Instagram;
